<template>
  <v-skeleton-loader
    class="mx-auto"
    type="image"
    style="height:80px;"
    v-if="!crl"
  ></v-skeleton-loader>
  <v-card v-else color="text-center" class="pa-2">
    <span style="font-size:1em;font-weight:bold;"
      >Download from TrustMonitor</span
    >
    <v-row class="mt-1">
      <v-col class="pt-0" cols="6">
        <v-btn
          class=""
          color="success"
          block
          text
          outlined
          @click.stop="download_pem()"
          ><v-icon class="mr-2">mdi-download</v-icon> PEM</v-btn
        >
      </v-col>
      <v-col class="pt-0" cols="6">
        <v-btn
          class=""
          color="success"
          block
          text
          outlined
          @click.stop="download_der()"
          ><v-icon class="mr-2">mdi-download</v-icon> DER</v-btn
        >
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import UserService from "../../store/UserService";

export default {
  name: "Download",
  props: ["crl"],
  methods: {
    download_pem: function() {
      UserService.downloadLocalFile(
        "crls/" + this.crl.id + "/pem/",
        this.crl.issuer_fqdn.friendly_name,
        "pem"
      ).catch(error => {
        console.log(error);
      });
    },
    download_der: function() {
      UserService.downloadLocalFile(
        "crls/" + this.crl.id + "/cer/",
        this.crl.issuer_fqdn.friendly_name,
        "cer"
      ).catch(error => {
        console.log(error);
      });
    }
  }
};
</script>

<style scoped></style>
