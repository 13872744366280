<template>
  <div>
    <v-data-table
      :headers="get_headers"
      dense
      :items="certs"
      class="elevation-0"
      flat
      style="border:0;"
      sort-by="valid_from"
      :sort-desc="true"
      :footer-props="{
        'items-per-page-options': [5, 10, 25, 100]
      }"
      :options="{ itemsPerPage: page_size }"
      :mobile-breakpoint="0"
      :search="search"
      :hide-default-footer="certs.length < 6"
    >
      <template v-slot:item.sha1="{ item }">
        <CertTile :certificate="item" :local="local"></CertTile>
      </template>

      <template v-slot:item.subject_fqdn.friendly_name="{ item }">
        <Fqdn :value="item.subject_fqdn" size="small"></Fqdn>
        <br />
        <span style="font-size:.8em;font-weight:bold;margin-left:5px;"
          >Issued by</span
        >
        <Fqdn :value="item.issuer_fqdn" size="x-small"></Fqdn>
      </template>

      <template v-slot:item.valid_from="{ item }">
        <span v-if="beforeNow(item.valid_from)">
          {{ item.valid_from | moment("MMM D YYYY") }}
        </span>
        <span v-else style="color:red;font-weight:bold;">
          {{ item.valid_from | moment("MMM D YYYY") }}
        </span>
      </template>

      <template v-slot:item.valid_to="{ item }">
        <span v-if="afterNow(item.valid_to)">
          {{ item.valid_to | moment("MMM D YYYY") }}
        </span>
        <span v-else style="color:red;font-weight:bold;">
          {{ item.valid_to | moment("MMM D YYYY") }}
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment/src/moment";
import Fqdn from "./Fqdn";
import CertTile from "./CertTile";

export default {
  name: "CertificateTable",
  components: { Fqdn, CertTile },
  props: [
    "certificates",
    "title",
    "search",
    "local",
    "default_page_size",
    "query"
  ],
  data: function() {
    return {
      filter: "Show All",
      filters: ["Show All"],
      loading: false,
      options: { itemsPerPage: 10 },
      tile_headers: [
        {
          text: "Certificate",
          sortable: false,
          value: "id"
        }
      ],
      table_headers: [
        {
          text: "",
          sortable: false,
          value: "sha1",
          width: 104
        },
        {
          text: "Subject",
          sortable: true,
          value: "subject_fqdn.friendly_name"
        },
        {
          text: "Valid From",
          sortable: true,
          width: 100,
          value: "valid_from"
        },
        {
          text: "Valid To",
          sortable: true,
          width: 100,
          value: "valid_to"
        }
      ],
      small_headers: [
        {
          text: "",
          sortable: false,
          value: "sha1",
          width: 104
        },
        {
          text: "Subject",
          sortable: true,
          value: "subject_fqdn.friendly_name"
        }
      ]
    };
  },
  computed: {
    page_size: function() {
      if (this.default_page_size) {
        return this.default_page_size;
      } else {
        return 10;
      }
    },
    get_headers: function() {
      if (this.display_type === 1) {
        return this.tile_headers;
      } else {
        if (this.$vuetify.breakpoint.smAndUp) {
          return this.table_headers;
        } else {
          return this.small_headers;
        }
      }
    },
    certs() {
      if (this.certificates) {
        return this.certificates;
      } else {
        let certs = this.$store.getters.certificate_settings;

        if (certs) {
          return certs.map(e => e.certificate);
        } else {
          return [];
        }
      }
    }
  },
  methods: {
    afterNow: function(time) {
      return moment(time).isAfter(moment());
    },
    beforeNow: function(time) {
      return moment(time).isBefore(moment());
    },
    showCertDialog: function(cert) {
      this.$store.dispatch("show_certificate_dialog", cert);
    }
  },
  mounted: function() {},
  watch: {}
};
</script>

<style scoped></style>
