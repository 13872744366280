<template>
  <v-row class="pa-2">
    <v-col class="pt-4" cols="12" lg="7" md="6">
      <v-skeleton-loader
        class="mx-auto"
        type="image"
        v-if="!crl"
        style="height:162px;"
      ></v-skeleton-loader>
      <v-alert
        v-else
        :loading="true"
        class="pt-2 mb-0"
        :style="`border-left:5px solid ${crl_status};`"
      >
        <span style="font-size:1.25em;font-weight:bold;">{{
          crl.issuer_fqdn.friendly_name
        }}</span>
        <br />
        <div class="pt-2" style="font-size:.8em;">
          <strong>Validity:</strong>

          <span v-if="not_before">{{
            crl.this_update | moment("MMM D YYYY")
          }}</span>
          <span v-else style="color:red">{{
            crl.this_update | moment("MMM D YYYY")
          }}</span>
          to
          <span v-if="not_after">{{
            crl.next_update | moment("MMM D YYYY")
          }}</span>
          <span v-else style="color:#FF5252;font-weight:bold;">{{
            crl.next_update | moment("MMM D YYYY")
          }}</span>
          <br />
          <strong>CRL Number:</strong> {{ crl.crl_number }}
          <br />
          <strong>Entries:</strong> {{ crl.entry_count }}
          <br />
          <strong>SHA1:</strong> {{ crl.sha1 }}
        </div>
      </v-alert>

      <v-skeleton-loader
        class="mx-auto mt-2"
        type="image"
        style="height:180px;"
        v-if="!crl"
      ></v-skeleton-loader>
      <v-card v-else class="pa-2 mt-2 mb-0">
        <v-card-title class="pa-0 ma-0">Content</v-card-title>
        <table style="width:100%;">
          <tr>
            <td colspan="2">
              <ChipValue
                v-if="crl.authority_key_id"
                label="AKID"
                :value="crl.authority_key_id.key_id"
                :search="
                  `/search/?authority_key_id=${crl.authority_key_id.key_id}`
                "
                copy="true"
              ></ChipValue>
            </td>
          </tr>
          <tr>
            <td>
              <ChipValue
                v-if="crl.signature_algorithm"
                label="Signature Algorithm"
                :value="crl.signature_algorithm.name"
                copy="true"
              ></ChipValue>
            </td>
          </tr>
        </table>
      </v-card>

      <v-skeleton-loader
        class="mx-auto mt-2"
        type="table"
        style="height:180px;"
        v-if="entries_loading"
      ></v-skeleton-loader>
      <v-card class="pa-0 mt-2" v-else>
        <v-card-title dense class="mb-0 pb-0"
          >CRL Entries
          <v-spacer></v-spacer>
          <v-sheet class="pa-0">
            <v-text-field
              v-model="entries_search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              dense
              class="pa-0 ma-0"
            ></v-text-field>
            <v-switch
              v-model="entries_hex"
              inset
              dense
              label="Show entries as hex"
              hide-details
            >
            </v-switch>
          </v-sheet>
        </v-card-title>
        <v-data-table
          :headers="entries_headers"
          :items="entries"
          class="elevation-1"
          :loading="entries_loading"
          :search="entries_search"
        >
          <template v-slot:item.serial="{ item }">
            <template v-if="entries_hex">{{ item.hex }}</template>
            <template v-else>{{ item.serial }}</template>
          </template>
          <template v-slot:loading>
            <LoadingSVG />
          </template>
        </v-data-table>
      </v-card>
    </v-col>

    <v-col class="pt-4" cols="12" lg="5" md="6">
      <CrlDownload :crl="crl" />

      <v-skeleton-loader
        class="mx-auto mt-2"
        type="image"
        style="height:180px;"
        v-if="!crl"
      ></v-skeleton-loader>
      <v-card v-else-if="crl.filecrls_set.length > 0" class="pa-2 mt-2">
        <v-card-title class="pa-0 ma-0">Public Sources</v-card-title>
        <Generic
          v-for="f in file_availability"
          :url="f.uri"
          v-bind:key="f.uri.id"
        ></Generic>
      </v-card>

      <v-skeleton-loader
        class="mx-auto mt-2"
        type="image"
        style="height:180px;"
        v-if="!crl"
      ></v-skeleton-loader>
      <v-card class="pa-0 mt-2" v-else-if="crl.issuer_public_key">
        <v-card-title dense class="pa-2 ma-0">Verified Issuers </v-card-title>
        <CertificateTable :certificates="issuers" />
      </v-card>
      <v-card v-else class="pa-0 mt-2" disabled>
        <v-card-title class="pa-2 ma-0">No Known Issuers</v-card-title>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import UserService from "../store/UserService";
import moment from "moment";
import ChipValue from "../components/ChipValue";
import Generic from "../components/uris/Generic";
import CertificateTable from "../components/CertificateTable";
import LoadingSVG from "../components/LoadingSVG";
import CrlDownload from "../components/crls/Download";

function isInt(value) {
  var x;
  if (isNaN(value)) {
    return false;
  }
  x = parseFloat(value);
  return (x | 0) === x;
}

export default {
  name: "Crl",
  components: { CrlDownload, LoadingSVG, CertificateTable, Generic, ChipValue },
  data: function() {
    return {
      id: this.$route.params.id,
      cert_table_headers: [
        { text: "", sortable: false, width: "104", value: "id" },
        { text: "Issuer", sortable: true, value: "issuer_fqdn.friendly_name" },
        { text: "Valid To", sortable: true, width: 90, value: "valid_to" },
        { text: "Status", sortable: true, width: 60, value: "is_revoked" }
      ],
      crl: null,
      loading: true,
      not_before: null,
      not_after: null,
      crl_status: null,
      entries: [],
      entries_loading: false,
      entries_search: null,
      entries_headers: [
        { text: "Serial", align: "left", sortable: true, value: "serial" },
        { text: "Reason", sortable: true, value: "reason" },
        { text: "Date", sortable: true, value: "date" }
      ],
      entries_hex: true,
      file_availability: [],
      file_availability_loading: false,
      issuers_loading: true,
      issuers_search: "",
      issuers: []
    };
  },
  created() {
    let self = this;
    if (isInt(this.id)) {
      UserService.getLocalApi("crls/" + this.id + "/").then(response => {
        this.crl = response.data;
        this.loading = false;
        let now = moment();
        this.not_after = moment(this.crl.next_update).isAfter(now);
        this.not_before = moment(this.crl.this_update).isBefore(now);
        if (self.not_after && self.not_before) {
          self.crl_status = "#4CAF50";
        } else {
          self.crl_status = "#FF5252";
        }
        if (this.crl.entry_count < 30000) {
          this.get_crl_entries();
        }
        if (this.crl.filecrls_set) {
          this.get_file_availability(this.crl.filecrls_set);
        }
        if (self.crl.issuer_public_key) {
          UserService.getLocalApi(
            "certificates/?public_key_sha1=" + self.crl.issuer_public_key.sha1
          ).then(response => {
            self.issuers = response.data.results;
            self.issuers_loading = false;
          });
        } else {
          self.issuers_loading = false;
        }
      });
    } else if (this.id.length === 40) {
      UserService.getRemoteApi("crls/?sha1=" + this.id).then(response => {
        this.crl = response.data.results[0];
        this.loading = false;
        let now = moment();
        this.not_after = moment(this.crl.next_update).isAfter(now);
        this.not_before = moment(this.crl.this_update).isBefore(now);
        if (self.not_after && self.not_before) {
          self.crl_status = "#4CAF50";
        } else {
          self.crl_status = "#FF5252";
        }
        if (this.crl.entry_count < 30000) {
          this.get_remote_crl_entries();
        }
        if (this.crl.filecrls_set) {
          this.get_remote_file_availability(this.crl.filecrls_set);
        }
        if (self.crl.issuer_public_key) {
          UserService.getLocalApi(
            "certificates/?public_key_sha1=" + self.crl.issuer_public_key.sha1
          ).then(response => {
            self.issuers = response.data.results;
            self.issuers_loading = false;
          });
        } else {
          self.issuers_loading = false;
        }
      });
    } else {
      // Not an int or sha1
      this.loading = false;
      this.$router.push("/404?err=Invalid Certificate Identifier");
    }
  },
  methods: {
    get_file_availability: function(files) {
      this.file_availability_loading = true;
      let file_id_list = files.map(e => e.file);
      UserService.getRemoteApi(
        "file-availability/?file_id=" + file_id_list
      ).then(response => {
        this.file_availability = response.data.results;
        this.file_availability_loading = false;
      });
    },
    get_crl_entries: function() {
      this.entries_loading = true;
      UserService.getRemoteApi("revocation-list/129638/").then(response => {
        this.entries = response.data.entries;
        this.entries_loading = false;
      });
    },
    get_remote_file_availability: function(files) {
      this.file_availability_loading = true;
      let file_id_list = files.map(e => e.file);
      UserService.getRemoteApi(
        "file-availability/?file_id=" + file_id_list
      ).then(response => {
        this.file_availability = response.data.results;
        this.file_availability_loading = false;
      });
    },
    get_remote_crl_entries: function() {
      this.entries_loading = true;
      UserService.getRemoteApi("revocation-list/129638/").then(response => {
        this.entries = response.data.entries;
        this.entries_loading = false;
      });
    }
  }
};
</script>

<style scoped></style>
